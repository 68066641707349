<section>
  <aside id="main-logo" (click)="goHome()">
    <picture class="mt-3">
      <img src ="./assets/img/myucrop.svg" alt="Home">
    </picture>
  </aside>
  <ion-list id="inbox-list">
    <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
      <ion-item routerDirection="root" [routerLink]="[p.page]" lines="none" detail="false" routerLinkActive="selected">
        <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
        <ion-label>{{ p.title }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>
</section>
