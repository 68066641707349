<span class="text-muted text-center">{{translationsLib.get('auth_select_language')}}</span>
<a *ngIf="language === 'es'" [matMenuTriggerFor]="languageMenu">
  Español
</a>
<a *ngIf="language === 'en'" [matMenuTriggerFor]="languageMenu">
  English
</a>
<a *ngIf="language === 'fr'" [matMenuTriggerFor]="languageMenu">
  Français
</a>
<a *ngIf="language === 'pt'" [matMenuTriggerFor]="languageMenu">
  Português
</a>
<mat-menu #languageMenu="matMenu">
  <a mat-menu-item (click)="changeLanguage('es')">
    Español
  </a>
  <a mat-menu-item (click)="changeLanguage('en')">
    English
  </a>
  <a mat-menu-item (click)="changeLanguage('fr')">
    Français
  </a>
  <a mat-menu-item (click)="changeLanguage('pt')">
    Português
  </a>
</mat-menu>
